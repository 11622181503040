import { theme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'
import '@fontsource/fira-code/variable.css'
import '@fontsource/mulish/variable.css'

import Button from './components/Button.theme'
import Checkbox from './components/Checkbox.theme'
import Drawer from './components/Drawer.theme'
import Heading from './components/Heading.theme'
import Popover from './components/Popover.theme'
import Table from './components/Table.theme'
import colors from './foundations/colors'
import config from './foundations/config'
import fonts from './foundations/fonts'
import styles from './styles'

export default extendTheme(
  {
    foundations: {
      config,
    },
    fonts,
    styles,
    colors: { ...theme.colors, ...colors },
    components: { Checkbox, Button, Drawer, Popover, Table, Heading },
  },
  theme
)
